@import "fonts";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/bootstrap";
@import "styles/global/datepicker";
@import "styles/global/treeview";
@import "styles/global/pdfview";
@import "styles/global/variables.scss";
@import "styles/global/buttons";
@import "styles/global/icon.scss";
@import "styles/global/filters";

body {
  background-color: var(--basic-200);
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Appliquer l'antialiasing aux éléments graphiques */
canvas, img, svg {
  image-rendering: optimizeQuality;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
}

/* Appliquer l'antialiasing aux textes */
body, html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

//region **custom classes**

// letter spacings
.ls-p1 {
  letter-spacing: 0.26px;
}

.ls-p2 {
  letter-spacing: 0.42px;
}

.ls-m1 {
  letter-spacing: -0.26px;
}

.ls-m2 {
  letter-spacing: -0.42px;
}

//endregion

.font-family-primary {
  font-family: var(--font-family-primary), "Open Sans", sans-serif;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

// NAV component styles for tender / local data modules
.tender-page {
  .custom-tooltip {
    .tooltip-inner {
      background-color: var(--basic-800);
    }

    .tooltip-arrow {
      &::before {
        border-right-color: var(--basic-800);
      }
    }
  }
}

.local-data-page {
  .custom-tooltip {
    .tooltip-inner {
      background-color: var(--primary-500);
    }

    .tooltip-arrow {
      &::before {
        border-right-color: var(--primary-500);
      }
    }
  }
}

.custom-tooltip {
  opacity: 1 !important;
  margin-left: 18px !important;
  width: max-content;
  padding: 0 0.4em;

  .tooltip-inner {
    font-weight: bold;
    font-size: 16px;
  }
}

.tooltip-alert-warning {
  z-index: 1230 !important;
  position: absolute !important;
  left: 19px !important;
  opacity: 1 !important;

  .tooltip-inner {
    font-size: 12px;
    min-width: 170px;
  }

  .arrow {
    left: 45px !important;
  }
}

.tooltip-alert-error {
  z-index: 1230 !important;
  position: absolute !important;
  left: 19px !important;
  opacity: 1 !important;

  .tooltip-inner {
    font-size: 12px;
    min-width: 280px;
  }

  .arrow {
    left: 45px !important;
  }

}

.alert-tooltip {
  margin-left: -10px;
  margin-top: 8px;
  opacity: 1 !important;
  height: 28px;
  width: 168px;
  text-align: center;
  bottom: 5px;
  left: 50px;

  .tooltip-inner {
    font-size: 12px;
    text-align: left;
    width: max-content;
    font-weight: normal;
    color: white;
    background-color: var(--alert-tooltip-color);
  }

  .arrow {
    margin-left: -10px;
    border-style: none;

    &::before {
      border-right-color: var(--alert-tooltip-color);
    }
  }
}

.info-section {
  .accordion-item {
    max-height: 100%;
    max-width: var(--action-panels-width-lg);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .collapse.show {
      overflow: auto;
    }
  }
}

//region part for admin doc search panel
.filter-content {
  .accordion > .accordion-item > .accordion-header {
    margin-bottom: 0;
  }
}

.filter {
  .accordion-header {
    border-bottom: none;
  }

  .accordion .accordion-header {
    padding: 10px 10px;
  }

  // potentiellement à supprimer
  .issuers-filter-subsection-container {
    .accordion-item {
      border: none;
    }

    .accordion, .accordion-header {
      border: none;
      padding: 0 16px;
      display: flex;

      ul {
        padding: 8px 0;
      }
    }

    .filter ul {
      padding-top: 0;
    }

    .accordion-body {
      border-top: solid 1px var(--basic-300);
    }

    .treeview-container {
      padding: 8px 16px;
    }
  }
}

.issuer-panel-header-btn {
  max-height: 26px;

  .issuer-chevron {
    width: unset;
    padding: 0;
  }
}

//endregion

.contain {
  height: 100%;
  overflow: auto;
}

.custom-tooltip-alias {
  opacity: 1 !important;

  .tooltip-inner {
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    background-color: #f9f9f9;
    font-size: 12px;
    color: var(--basic-800);
    text-align: left;
    min-width: 270px;
    width: max-content;
  }

  .arrow {
    display: none;
  }
}

.custom-tooltip-search {
  opacity: 1 !important;

  .tooltip-inner {
    font-size: 12px;
    width: 315px;
  }
}

.custom-tooltip-sources {
  opacity: 1 !important;

  .tooltip-inner {
    font-size: 12px;
    max-width: max-content;
    padding: 12px 16px;

    .sources-list {
      list-style-type: none;
      padding: 0;
      margin: 0;

      .source {
        white-space: nowrap;
      }

      .source:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .arrow {
    right: 8px;
  }
}

.custom-tooltip-emails-list {
  .tooltip-inner {
    padding: 6px 12px 6px 10px;
    font-size: 10px;

    .emails-list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.custom-tooltip-smart-grid-indicator {
  min-width: 282px;
}

.custom-tooltip-alert {
  z-index: 1230 !important;
  position: absolute !important;
  left: 32px !important; // opt2
  opacity: 1 !important;

  .tooltip-inner {
    font-size: 12px;
    min-width: 168px;
  }

  .arrow {
    left: 45px !important; // opt2
  }
}

.custom-tooltip-article-card-territories {
  z-index: 3 !important;
  position: absolute !important;
  min-width: 100% !important;
  opacity: 1 !important;
  font-weight: 600;

  .tooltip-inner {
    font-size: 14px;
    min-width: 100%;
  }

  .arrow {
    position: absolute;
    right: 5% !important;
  }
}


@media (min-width: 992px) {
  .custom-tooltip-alerts {
    margin-top: 5px;
    z-index: 1230 !important;
    position: absolute !important;
    opacity: 1 !important;

    .tooltip-inner {
      font-size: 12px;
      min-width: 359px;
    }
  }


}

.custom-tooltip-active-alert {
  z-index: 3230 !important;
  position: absolute !important;
  left: 50px !important; // opt2
  opacity: 1 !important;

  .tooltip-inner {
    font-size: 12px;
    min-width: 168px;
  }

  .arrow {
    left: 15% !important; // opt2
  }
}

.custom-tooltip-alert-obsolete {
  margin-top: 5px;
  z-index: 1230 !important;
  position: absolute !important;
  left: 73px !important; // opt2
  opacity: 1 !important;

  .tooltip-inner {
    font-size: 12px;
    min-width: 212px;
    color: #ffffff;
  }

  .arrow {
    left: 12% !important; // opt2
  }
}

.custom-tooltip-trash {
  z-index: 1230 !important;
  position: absolute !important;
  opacity: 1 !important;

  .tooltip-inner {
    font-size: 12px;
    color: #ffffff;
  }

  .arrow {
    left: 60% !important; // opt2
  }
}

.custom-tooltip-alerts {
  margin-top: 5px;
  z-index: 1230 !important;
  position: absolute !important;
  opacity: 1 !important;

  .tooltip-inner {
    font-size: 12px;
  }
}

.custom-tooltip-attribution, .custom-tooltip-soon {
  max-width: none;  /* largeur maximale */
  .tooltip-inner {
    word-wrap: break-word !important;
    text-align: left !important;
    white-space: normal !important;
    color: var(--Basic-100, var(--Solid-Basic-100, #FFF));
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
.custom-tooltip-attribution .tooltip-inner {max-width: 420px !important;}
.custom-tooltip-soon .tooltip-inner {min-width: 330px !important;}

.tooltip-term-lock {
  opacity: 1 !important;
  padding-left: 300px;

  .tooltip-inner {
    line-height: 2.5;
    font-size: 12px;
    text-align: left;
    min-width: 470px;
  }
}

.custom-popover .popover-body {
  padding: var(--Size75, 4px) var(--Size100, 8px);
  border-radius: 4px;
  color: var(--basic-1000);
  background: var(--basic-100);
  box-shadow: 0 0 8px 0 var(--transparent-basic-48);
  gap: var(--Size75, 4px);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  font-style: normal;
  display: flex;
  flex-direction: column;

  i {
    opacity: 1;
  }
}

.custom-tooltip-smart-grid-cards {
  opacity: 1 !important;
  --bs-tooltip-bg: var(--basic-1000);

  &.max-width {
    width: max-content;
  }

  &.max-pin-tooltip {
    .tooltip-inner {
      max-width: 213px;
    }
  }

  .tooltip-inner {
    font-size: 12px;
    color: white;
  }
}

.tooltip-share-watch {
  min-width: 150px;
  min-height: 100%;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;

  .tooltip-inner {
    white-space: nowrap;
    max-width: none;
  }

  .tooltip-arrow {
    &::before {
      bottom: -2px !important;
    }
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

em {
  background-color: #FEE2D6;
  border-radius: 6px;
  font-weight: bold;
  font-style: normal;
  height: 24px;
  padding: 1px 4px;

  &.selected {
    background-color: #fd9468;
  }

}

.not-highlight {
  em {
    all: unset;
  }
}

.card-board-obsolete {
  padding: 22px;
  font-size: 14px;
  border-radius: 16px;
  background-color: var(--basic-100);
  color: var(--basic-800);
  padding-right: 9px !important;
  transition: 0.3s ease-in-out;
  border: 1px solid var(--basic-400);

  .label-occurrence {
    background-color: var(--basic-300);
    height: 24px;
    border-radius: 8px;
    padding: 0 8px;
    line-height: 2;
    font-size: 12px;
    font-weight: 600;
    width: fit-content;
    min-width: 100px;
    text-align: center;
  }

  &:hover {
    box-shadow: none;

    .delete, .obsolete-setting {
      opacity: 1;
    }
  }

  &:not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom-width: 0;
  }

  &:not(:first-of-type) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }


  .card-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 0;

    &:hover {
      color: var(--primary-500);
    }
  }

  .card-info {
    font-weight: 600;
    color: var(--basic-600);
    font-size: 12px;
    padding-top: 15px;

    > div:first-child {
      padding-right: 64px;
    }
  }

  .card-text {
    padding-top: 14px;
    font-size: 14px;
  }

  img {
    padding-right: 8px;
  }

}

.card-board-first {
  font-size: 14px;
  background-color: var(--basic-100);
  padding: 24px;
  color: var(--basic-800);
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border: 1px solid var(--basic-400);
  border-bottom-width: 0;
  padding-right: 9px !important;

  &:hover {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .2);
  }

  &:not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:not(:first-of-type) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  border-radius: 16px;

  .label-occurrence {
    background-color: var(--basic-300);
    height: 24px;
    border-radius: 50px;
    padding: 0 8px;
    line-height: 2;
    font-size: 12px;
    font-weight: 600;
    width: fit-content;
    min-width: 100px;
    text-align: center;
  }

  .card-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 0;

    &:hover {
      color: var(--primary-500);
    }
  }

  .card-info {
    font-weight: 600;
    color: var(--basic-600);
    font-size: 12px;
    padding-top: 15px;

    > div:first-child {
      padding-right: 64px;
    }
  }

  .card-text {
    padding-top: 14px;
    font-size: 14px;
  }

  img {
    padding-right: 8px;
  }

  .rename {
    padding-left: 16px;
  }
}

.card-board {
  font-size: 14px;
  border-radius: 16px;
  background-color: var(--basic-100);
  padding: 24px;
  color: var(--basic-800);
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border: 1px solid var(--basic-400);

  &:hover {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .2);
  }

  &:not(:last-of-type) {
    border-bottom-width: 0;
  }

  .label-occurrence {
    background-color: var(--basic-300);
    height: 24px;
    border-radius: 50px;
    padding: 0 8px;
    line-height: 2;
    font-size: 12px;
    font-weight: 600;
    width: fit-content;
    min-width: 100px;
    text-align: center;
  }

  .card-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 32px;
    margin-bottom: 0;

    &:hover {
      color: var(--primary-500);
    }
  }

  .card-info {
    font-weight: 600;
    color: var(--basic-600);
    font-size: 12px;
    padding-top: 15px;

    > div:first-child {
      padding-right: 64px;
    }
  }

  .card-text {
    padding-top: 14px;
    font-size: 14px;
  }

  img {
    padding-right: 8px;
  }

  .rename {
    padding-left: 16px;
  }
}

.accordion-item {
  border-radius: 16px !important;

  & + .accordion-item {
    margin-top: 16px;
  }

  .accordion-header {
    padding: 10px;
    background-color: transparent;
    border-bottom: var(--basic-300) 1px solid;
    color: var(--basic-600);
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;

    button {
      padding: 0;
      font-size: inherit;
      color: inherit;
      font-weight: inherit;
      text-decoration: none;
      text-transform: inherit;
      width: 100%;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .accordion-body {
    padding: 0;
    font-size: 14px;
  }
}

.icon {
  height: 16px;
  width: 16px;
  background-color: var(--basic-600);
  margin-right: 8px;
  mask-repeat: no-repeat;

  &--calendar {
    mask-image: url(/assets/images/icon-calendar.svg);
  }

  &--article {
    mask-image: url(/assets/images/icon-article.svg);
  }

  &--globe {
    mask-image: url(/assets/images/icon-globe.svg);
  }

  &--admin-doc {
    mask-image: url(/assets/images/icon-docs.svg);
  }

  &--impacter {
    mask-image: url(/assets/images/icon-people.svg);
    background-color: var(--basic-600);
  }

  &--triangle {
    mask-image: url(/assets/images/icon-alert-triangle.svg);
    background-color: var(--primary-500);
  }

  &--close-circle {
    mask-image: url(/assets/images/icon-close-circle.svg);
    background-color: var(--danger-500);
  }

  &--close {
    mask-image: url(/assets/images/icon-close.svg);
    background-color: var(--basic-100);
  }

  &--check-circle {
    mask-image: url(/src/assets/images/check-circle-old.svg);
    background-color: #00b383;
  }

  &--cross-circle {
    mask-image: url(/assets/images/cross-circle.svg);
    background-color: var(--danger-500);
  }

  &--forbidden {
    mask-image: url(/assets/images/forbidden.svg);
    background-color: var(--danger-500);
  }

  &--chevron-down {
    mask-image: url(/assets/images/icon-chevron-down.svg);
  }

}

.content-link {
  color: inherit;
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;

  &:hover {
    text-decoration: none;
  }

  &:visited {
    color: inherit;
  }
}

/* Animation */

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .is-mobile {
    display: block;
  }

  .is-desktop {
    display: none;
  }

  h1 {
    font-size: 1.5rem;
  }
}

.modal-content {
  border-radius: 16px;
  box-shadow: 0 0 12px 0 rgb(0 0 0 / 12%);
  border: solid 1px var(--basic-100);
}

.modal-width-419 {
  .modal-content {
    width: 419px;
    margin: auto;
  }
}

.modal-width-medium .modal-dialog {
  max-width: 424px;
}

.modal-width-560 .modal-dialog {
  max-width: 560px;
}

.modal-width-696 .modal-dialog {
  width: 696px;
  height: 546px;
  max-width: 696px;
  max-height: 546px;

  .modal-content {
    width: 696px;
    height: 546px;
    max-height: 546px;
    margin: auto;
  }
}

.modal-width-716 .modal-dialog {
  width: 716px;
  max-width: 716px;

  .modal-content {
    width: 716px;
    margin: auto;
  }
}

.modal-auto {
  .modal-dialog {
    max-width: 1024px;
    max-height: 768px;
  }

  .modal-content {
    width: fit-content;
    height: fit-content;
    margin: auto;
  }
}

.google-form-modal {
  overflow: hidden;

  .modal-content {
    background-color: #f6f6f6;
    min-width: 660px;
  }
}

.modal-dialog {
  max-width: 525px;

}

.custom-modal-update .modal-dialog, .user-watch-modal .modal-dialog {
  max-width: 630px;
}

.lg-modal {
  .modal-dialog {
    max-width: 80vw;
  }
}

.md-modal {
  .modal-dialog {
    max-width: 50vw;
  }
}

.icon-block {
  height: 16px;
  width: 16px;
  margin-right: 4px;
  mask-repeat: no-repeat;
  background-color: var(--basic-100);

  &--list {
    mask-image: url(/assets/images/icon-list.svg);
  }

  &--swap {
    mask-image: url(/assets/images/icon-swap.svg);
  }

  &--shuffle {
    mask-image: url(/assets/images/icon-shuffle.svg);
  }

  &--lock {
    mask-image: url(/assets/images/icon-lock-fill.svg);
    background-color: var(--basic-600);
    margin-right: 0;
    margin-bottom: -2px;
  }

  &--trash {
    mask-image: url(/assets/images/icon-trash.svg);
    background-color: #DB2C66;
    cursor: pointer;
    margin-right: 0;
  }

  &--forbiden {
    mask-image: url(/assets/images/forbidden.svg);
    background-color: #DB2C66;
    cursor: pointer;
    margin-right: 0;
  }
}

.color-white {
  color: white;
}

.color-basic-300 {
  color: var(--basic-300);
}

.color-basic-400 {
  color: var(--basic-400);
}

.color-basic-600 {
  color: var(--basic-600);
}

.color-basic-800 {
  color: var(--basic-800) !important;
}

.color-primary-500 {
  color: var(--primary-500) !important;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.font-14px {
  font-size: 14px;
}

.font-13px {
  font-size: 13px;
}

.font-12px {
  font-size: 12px;
}

.font-16px {
  font-size: 16px;
}

.font-18px {
  font-size: 18px;
}

.font-20px {
  font-size: 20px;
}

.font-22px {
  font-size: 20px;
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .is-mobile {
    display: none;
  }

  .is-desktop {
    display: block;
  }
}


html, body {
  height: 100%;
}

.tooltip_max_width_300 .tooltip-inner {
  max-width: 300px;
}

.cursor-pointer {
  cursor: pointer;
}

.default-tooltip-class {
  z-index: 1230 !important;
  position: absolute !important;

  .tooltip-inner {
    text-align: left;
  }

  font-size: 12px;
  font-family: var(--font-family-primary), "Open Sans", sans-serif;
}

.share-tooltip-default {
  .tooltip-inner {
    text-align: left;
  }

  font-size: 12px;
  font-family: var(--font-family-primary), "Open Sans", sans-serif;
}

.tooltip-max-190 {
  .tooltip-inner {
    max-width: 190px;
  }
}

.tooltip-max-300 {
  .tooltip-inner {
    max-width: 300px;
  }
}

.tooltip-fixed-226 {
  .tooltip-inner {
    width: 226px;
    max-width: 226px;
  }
}

.tooltip-bottom-middle-right {
  @extend .share-tooltip-default;
  margin-left: 11px;
}

.total-step-width {
  width: min(100%, var(--alert-funnel-total-step-width));
}

.mention {
  font-weight: 600;
  text-decoration-line: underline;
  color: #223245;
  cursor: pointer;
}

// Zoom section inside pdf-view
.pdf-menus-section {
  font-size: 14px;
  color: var(--basic-800);

  #scaleSelectContainer {
    min-height: 28px !important;
    color: var(--basic-800) !important;
  }

  #scaleSelect {
    max-width: 38px !important;
    min-height: 28px;
    color: var(--basic-800) !important;
  }
}

.territory-names-tooltip {
  opacity: 1 !important;
  min-width: 250px;
  display: flex;

  .tooltip-inner {
    border: solid 12px black;
    max-height: 184px;
    font-size: 12px;
    min-width: 100%;
    text-align: left;
    line-height: 16px;
    padding: 0 4px;

    display: -webkit-box;
    -webkit-line-clamp: 9;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}

// new "NOUVEAU" tag
.new-tag {
  font-style: italic;
  font-weight: 700;
  font-size: 10px;
  line-height: 11px;
  color: var(--primary-500);
  text-align: center;
  padding: 2px 4px;
  background-color: #fee8cc;
  border-radius: 12px;
  height: 15px;
  margin-left: 8px;
}

// quick backgrounds colors for divs
.bg-white {
  background-color: white;
}

.bg-basic-100 {
  background-color: var(--basic-100);
}

.bg-basic-200 {
  background-color: var(--basic-200);
}

.bg-basic-300 {
  background-color: var(--basic-300);
}

.bg-basic-400 {
  background-color: var(--basic-400);
}

.bg-basic-500 {
  background-color: var(--basic-500);
}

.bg-basic-600 {
  background-color: var(--basic-600);
}

.bg-basic-700 {
  background-color: var(--basic-700);
}

.bg-basic-800 {
  background-color: var(--basic-800);
}

.border-radius-8px {
  border-radius: 8px;
}

.border-radius-16px {
  border-radius: 16px;
}

// margins
.mgl-4px {
  margin-left: 4px;
}

.mgl-6px {
  margin-left: 6px;
}

.mgl-8px {
  margin-left: 8px;
}

.mgl-16px {
  margin-left: 16px;
}

.mgb-16px {
  margin-bottom: 16px;
}

// paddings
.pd-2px {
  padding: 2px;
}

.pd-4px {
  padding: 4px;
}

.pd-8px {
  padding: 8px;
}

.pd-16px {
  padding: 16px;
}

.pdb-24px {
  padding-bottom: 24px;
}

.pdb-16px {
  padding-bottom: 16px;
}

.pdb-8px {
  padding-bottom: 8px;
}

.pdb-4px {
  padding-bottom: 4px;
}

.pdt-24px {
  padding-top: 24px;
}

.pdt-16px {
  padding-top: 16px;
}

.pdt-8px {
  padding-top: 8px;
}
.pdt-7px {
  padding-top: 7px;
}
.pdt-2px {
  padding-top: 2px;
}

// line limitations

.text-limited-2-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limite à deux lignes */
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal {
  z-index: 1056 !important;
}

.accordion-body {
  padding: 0 !important;
}

.bs-tooltip-end, .bs-tooltip-start {
  pointer-events: none;
  padding: 0 0.4rem;

  .tooltip-arrow {
    padding: 0 0.4rem;
  }
}

.bs-tooltip-top, .bs-tooltip-bottom {
  pointer-events: none;
  padding: 0.4em 0;

  .tooltip-arrow {
    padding: 0.4em 0;
  }
}

.btn-link {
  --bs-btn-active-color: inherit;
  --bs-btn-color: rgba(34, 50, 69, 0.5);
}

.btn-close {
  background-image: url("assets/images/icon-close-circle.svg");
  background-size: 24px;
}

// V2 - partie appels offre
.tender-filter-container {

  // Partie pour les enfants : filter-date, filter-territory, filter-topic
  .filter-name {
    font-size: 12px;
    font-weight: 700;
    color: var(--basic-600);
    padding: 2px 16px 4px;
  }

  .filter-search-container {
    padding: 12px 12px 0 12px;
  }

  .icon-filter--close, .icon-filter--search {
    top: 21px;
  }

  .treeview-container {
    overflow-x: hidden;
    padding: 0.5rem 1rem 1rem 1rem;
  }

  //END of partie//

  > * + * {
    margin-left: 8px;
  }

  app-filter-topic {
    &.multi-search-view {
      margin-left: 0;
    }

    width: 280px;
  }

  app-filter-text-and-items {
    width: 400px;
  }

  app-filter-date {
    width: 216px;
    &.lg {
      width: 410px;
    }
  }

  app-filter-territory {
    width: 216px;
  }

  app-filter-type {
    width: 216px;
  }

  .treeview-item {
    position: relative;
    left: 2px;
  }

  .form-check-input:checked, .form-check-input:indeterminate {
    border-color: transparent;
    background-color: var(--transparent-primary-16);
    filter: invert(65%) sepia(99%) saturate(2965%) hue-rotate(359deg) brightness(98%) contrast(101%);
  }

  &.loading-filters {
    opacity: 0.5;
    pointer-events: none;
  }
}

.topics-dp-content {
  .treeview-container {
    padding: 8px 0;
  }

  .treeview-item {
    width: 100%;
    padding: 0 12px;
    cursor: pointer;

    .wrapper {
      &.item-disabled {
        cursor: not-allowed;

        .checkbox, .form-check-label {
          cursor: not-allowed;
        }
      }
    }

    .wrapped-content {
      margin: 2px 0;
    }
  }

  .main-treeview-item {
    display: flex;
    align-items: center;

    &:hover {
      background-color: var(--basic-200);
    }
  }
}

// toast du service ToastMessageStackService
.toast {
  border: none;
}

.toast-body {
  width: 100% !important;

  .toast-image {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
}

// Partie tenders
.ngb-dp-weekday {
  color: rgba(252, 76, 2, 0.5) !important;
}

// modale de visualisation des html d'avis d'AO
.modal-xxl {
  min-width: 900px !important;
}

.pdl-2px {
  padding-left: 2px;
}

.pdl-4px {
  padding-left: 4px;
}

.pdl-8px {
  padding-left: 8px;
}

.pdr-4px {
  padding-right: 4px;
}

.pdr-8px {
  padding-right: 8px;
}

.pdr-12px {
  padding-right: 12px;
}

.pdl-12px {
  padding-left: 12px;
}

.pdl-16px {
  padding-left: 16px;
}

.pdr-16px {
  padding-right: 16px;
}

.bdr-4px {
  border-radius: 4px;
}

.gap-4px {
  gap: 4px;
}

// tenders
.tender-zoom-container {
  font-size: 14px !important;
  color: white !important;
  font-weight: 600 !important;

  #scaleSelectContainer {
    min-height: 32px !important;
    color: white !important;
  }

  #scaleSelect {
    box-sizing: border-box;
    border: solid 1px white !important;
    color: white !important;
    max-width: 80px !important;
    min-height: 32px !important;
    background-color: var(--transparent-basic-white-16) !important;
  }
}

// Barre de recherche pour les documents du DCE, basée sur AppControlFComponent
.custom-toolbar-container .control-f-container {
  .search-bar {
    flex-grow: 1;

    input:focus {
      flex-grow: 1;
    }

    input {
      color: var(--basic-600);
      border-radius: 10px;
      background-color: white;

      &::placeholder {
        color: var(--basic-600);
        font-weight: 600 !important;
      }
    }

    #search-test {
      background-color: var(--basic-100);
      box-sizing: border-box;
      border: solid 1px var(--basic-500);
    }

    .close-icon-button {
      background-color: transparent !important;
    }
  }
}

.custom-tooltip-tender-name {
  .tooltip-inner {
    font-size: 12px;
  }
}

.tenders-dropdown-content > ex-tree-node > .tree-node-container {
  padding: 8px;
}

.tender-title-tooltip {
  > .tooltip-inner {
    max-width: 480px;
    font-size: 12px;
    text-align: left;
  }
}

.tender-suggestion-tooltip {
  > .tooltip-inner {
    min-width: 360px;
    max-width: 480px;
    font-size: 12px;
    text-align: left;
  }
}

.tender-inquirers-tooltip {
  > .tooltip-inner {
    max-width: 480px;
    font-size: 12px;
    text-align: left;
  }
}

.tender-territories-tooltip {
  > .tooltip-inner {
    max-width: 480px;
    font-size: 12px;
    text-align: left;
  }
}

.tender-remaining-days-tooltip {
  > .tooltip-inner {
    max-width: 480px;
    font-size: 12px;
  }
}

.tooltip-watch-appearance {
  margin-top: 5px;
  opacity: 1 !important;

  .tooltip-inner {
    font-size: 12px;
    min-width: 200px;
    text-align: left;
  }
}

.primary-explain-button {
  height: 40px;
  padding: 6px 12px;
  border-radius: 8px;
  border: none;
  color: var(--basic-100);
  background-color: var(--primary-500);

  &:disabled {
    opacity: 0.2;
    border: none;
  }

  &:hover {
    background-color: var(--primary-600);
  }

  &:active {
    background-color: var(--primary-500);
  }
}

.mw-tooltip {
  .tooltip-inner {
    font-size: 12px;
  }
}

.simple-tooltip {
  > .tooltip-inner {
    min-width: 160px;
    max-width: 480px;
    font-size: 12px;
    white-space: pre-line;
  }

  &.fit {
    > .tooltip-inner {
      white-space: nowrap;
      min-width: unset;
    }
  }

  &.text-left {
    > .tooltip-inner {
      text-align: left;
    }
  }
}

.simple-ph-icon-tooltip {
  position: relative;
  left: 12px;

  .tooltip-inner {
    width: fit-content;
    max-width: 480px;
    font-size: 12px;
  }
}

app-tender-pdfs-reader {
  .chunk-box {
    position: absolute;
    background: var(--active-100);
    left: 1%;
    width: 97.5%;
    border-left: 1px dashed var(--color-semantic-ai-bg);
    border-right: 1px dashed var(--color-semantic-ai-bg);

    &.both-chunk {
      border-radius: 8px;
      border-top: 1px dashed var(--color-semantic-ai-bg);
      border-bottom: 1px dashed var(--color-semantic-ai-bg);
    }

    &.top-chunk {
      border-radius: 8px 8px 0 0;
      border-top: 1px dashed var(--color-semantic-ai-bg);
      border-bottom: 0;
    }

    &.bottom-chunk {
      border-radius: 0 0 8px 8px;
      border-top: 0;
      border-bottom: 1px dashed var(--color-semantic-ai-bg);
    }

    &.none-chunk {
      border-top: 0;
      border-bottom: 0;
    }
  }
}

.tender-pdfs-dropdown-container {
  flex-grow: 1;
  max-width: 360px;

  .ex-dropdown-header {
    > input {
      &::placeholder {
        color: var(--basic-800) !important;
      }
    }
  }
}

// pour contrer la taille minimal de la toolbar du lecteur pdf qui semble être spécifiquement 33px
.tender-pdfs-viewer {
  #mainContainer {
    margin-top: -33px !important;

    &:before {
      content: '';
      position: absolute;
      inset: 0;
      margin-top: 33px;
      z-index: 100;
      border-top-left-radius: 16px;
      pointer-events: none;
      box-shadow: 36px 32px 13px 0 rgba(55, 84, 139, 0.00) inset,
      23px 20px 12px 0 rgba(55, 84, 139, 0.02) inset,
      13px 11px 10px 0 rgba(55, 84, 139, 0.07) inset,
      6px 5px 8px 0 rgba(55, 84, 139, 0.13) inset,
      1px 1px 4px 0 rgba(55, 84, 139, 0.14) inset;
    }
  }

  .page {
    border: none !important;
    border-radius: 4px;
    border-image: none !important;
    margin: 16px auto !important;

    .canvasWrapper {
      border-radius: 4px;
    }
  }
}


.user-watch-tooltip {
  opacity: 1 !important;
  min-width: 250px;
  display: flex;

  .tooltip-inner {
    border: solid 12px black;
    max-height: 184px;
    font-size: 12px;
    min-width: 100%;
    text-align: left;
    line-height: 16px;
    padding: 0 4px;

    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis !important;
  }
}

// Part for styling dropdown on tender-status component.
app-tender-status-selector, app-filter-status {
  .item {
    height: 30px;
    font-size: 12px !important;
    padding: 0 4px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600 !important;

    &:hover {
      background-color: var(--basic-200);
      border-radius: 4px;
    }

    .color-square {
      font-size: 16px;
      padding-right: 8px;
    }
  }

  .items-container {
    padding: 8px;
  }

  .ex-dropdown-item {
    &:disabled {
      background-color: var(--basic-300) !important;
      border-radius: 4px;
      color: var(--basic-800) !important;
    }
  }

  .ex-dropdown-menu {
    width: 160px;
  }
}

app-tender-status-selector {
  &:hover {
    cursor: pointer;

    .chevron-d-hover .chevron {
      display: flex !important;
    }

    .no-status-selected {
      .ex-dropdown-header {
        color: var(--basic-600) !important;
      }
    }
  }

  ex-dropdown:hover {
    .ex-dropdown-header {
      background-color: color-mix(in srgb, currentcolor 10%, transparent) !important;
    }
  }

  // gestion des border radius sur partie droite
  .small ex-dropdown:hover .ex-dropdown-header {
    border-radius: 0 8px 8px 0 !important;
  }

  .x-small ex-dropdown:hover .ex-dropdown-header {
    border-radius: 0 4px 4px 0 !important;
  }

  .ex-dropdown-container, .ex-dropdown-header {
    border: none !important;
    background-color: transparent !important;
    border-radius: 0 !important;
  }

  .ex-dropdown-input {
    margin: 0 !important;
  }
}

// Part for styling dropdown on note editor component.
ex-note-editor, app-tender-share {
  .ex-dropdown-menu {
    right: 0 !important;
    left: unset !important;
  }

  &:hover {
    .ex-dropdown-header {
      background-color: var(--basic-300) !important;
    }
  }
}

ex-note-editor.has-tender-note {
  background-color: var(--basic-100);
}

// Part for common styling note and status
.column-status {
  ex-note-editor {
    .ex-dropdown-menu {
      right: 16px !important;
      top: calc(100% - 10px);
    }

    .ex-dropdown-header {
      border-radius: 4px !important;
    }

    .ex-dropdown-input {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

app-tender-detail .ex-dropdown-input {
  justify-content: center;
}

app-filter-status {
  .item {
    .ex-dropdown-input {
      margin: 0 0 0 8px !important;
    }
  }

  .ex-dropdown-container {
    height: 24px !important;
  }

  .ex-dropdown-header {
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--basic-300) !important;
    }
  }

  .ex-dropdown-input {
    margin: 0 !important;
  }
}

app-tenders-board {
  .tender-filter-container app-filter-territory {
    margin-left: 0;
  }
}

.text-basic {
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;
  color: var(--basic-800);
  font-weight: 600 !important;
}

app-tenders-search app-tenders-filter .ex-dropdown-container .ex-dropdown-input {
  width: 88px;
}

.shadow-border {
  box-shadow: 0 1px 4px 0 var(--transparent-basic-12), 0 1px 2px 0 var(--transparent-basic-12);
  border: solid 1px var(--basic-200);
}

.ex-shadow {
  box-shadow: 0 1px 4px 0 var(--transparent-basic-12), 0 1px 2px 0 var(--transparent-basic-12);
}

.cdk-virtual-scroll-spacer {
  background-color: var(--basic-100);
  border-radius: 16px;
}

app-tenders {
  em {
    background-color: var(--ex-highlight-text);
  }
}

// TODO: change to ex button design system variant
.assignment-button, .share-button {
  button {
    background-color: var(--basic-250) !important;
    color: var(--basic-900);

    &:hover {
      background-color: var(--basic-300) !important;
      box-shadow: var(--box-shadow-tender-btn);
    }
  }
}
